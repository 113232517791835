<template>
  <invitations-index />
</template>

<script>
import Vue from 'vue';
import InvitationsIndex from '~/pages/shared/invitations/InvitationsIndex';

export default Vue.extend({
  components: {
    InvitationsIndex,
  },

  middleware: 'tenantmember',
});
</script>
